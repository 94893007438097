import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Map from './components/Map';

//Pages
import Home from './pages/Home';

import "./app.css"




function App() {
  return (
      <>
        <NavBar></NavBar>
        <Home className="poppins_reg"></Home>
        <Map></Map>
        <Footer></Footer>
      </>
  );
}

export default App;
