import React from 'react'
import ServicesSummaryDisplay from '../components/ServicesSummaryDisplay'
import ServicesJson from '../db/services.json'

let Services_Json = ServicesJson.services[0]

function ServicesPage(props) {
  let Services_Json_map = {}
  const queryParameters = new URLSearchParams(window.location.search)
  let selectedService = queryParameters.get("service")

  Services_Json.map(data => {
    Services_Json_map[data.linkName] = data
  })

  return (<>
            {
              selectedService != null ? <>
                <div className='container mt-5 mb-5'>
                    <div className='row justify-content-evenly'>
                      <div className='col-12 col-sm-6'>
                          <h1>
                            {Services_Json_map[selectedService].title}
                          </h1>
                            <img className="card-img-top" src={Services_Json_map[selectedService].img_src}></img>
                            {Services_Json_map[selectedService].text.map(e => {
                              return <p>{e}</p>
                            })}
                      </div>
                    </div>
                </div>
              </> : <>
              </> 
            }
            <ServicesSummaryDisplay 
                displaySummaryFull={props.displaySummaryFull}/> 
            
          </>)  
}

export default ServicesPage
