import React from 'react'

let profileImageStyle = {
    width: '350px',
    height: '475px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover'
}

export default function AboutLong() {
  return (
    <div className='container mt-5 mb-5'>
          <div className='row justify-content-evenly'>
            <div className='col-12 col-sm-6 d-flex justify-content-evenly mb-3'>
                <img src="./images/profile.jpg" style={profileImageStyle}></img>
            </div>
            <div className='col-12 col-sm-6'>
                <h5>
                  Dr. Nguyen graduated from University of Houston with a degree in Biology.  
                  He earned his medical degree from Lake Erie College of Osteopathic Medicine.  
                  Dr. Nguyen is board certified in Allergy, Asthma, and Immunology for Pediatrics and Adults.  
                  He is also board certified in Internal Medicine.  
                  He is a member of the American College of Allergy, Asthma and Immunology as well as the American Academy of Allergy and Immunology.
                </h5>
                <br></br>
                <h5>
                  Dr. Nguyen is dedicated to providing comprehensive evidence-based care along with shared-decision making.  
                  His area of focus includes allergic/non-allergic rhinitis, asthma, chronic sinusitis, atopic dermatitis, food allergy, immune system disorders, 
                  urticaria, and more.
                </h5>
                <br></br>
                <h5>
                  In his spare time, he enjoys spending time with family and friends. He likes tennis, jogging, gardening, fishing, and hunting.
                </h5>
            </div>
          </div>
    </div>
  )
}
