import React from 'react'

let profileImageStyle = {
  width: '350px',
  height: '475px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover'
}

export default function AboutShort() {
  return (
    <div className='container mt-5 mb-5'>
          <div className='row justify-content-evenly'>
            <div className='col-12 col-sm-6 d-flex justify-content-evenly mb-3'>
                <img src="../images/profile.jpg" style={profileImageStyle}></img>
            </div>
            <div className='col-12 col-sm-6'>
                <h4>
                    Dr. Nguyen graduated from University of Houston with a degree in Biology.  
                  He earned his medical degree from Lake Erie College of Osteopathic Medicine.  
                  Dr. Nguyen is board certified in Allergy, Asthma, and Immunology for Pediatrics and Adults.  
                  He is also board certified in Internal Medicine.  
                  He is a member of the American College of Allergy, Asthma and Immunology as well as the American Academy of Allergy and Immunology....
                </h4>
                <a href="/about" class="learn-more-link btn btn-outline-dark">Learn More</a>

            </div>
          </div>
    </div>
  )
}
