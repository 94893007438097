import React from 'react'

//import services json
import ServicesJson from '../db/services.json'

let cardStyle = {
    width: "23rem", 
    padding: "0" ,
    margin: ".5rem"
}

let cardImageStyle = {
    width:"100%",
    height: "13vw",
    objectFit: "cover"
}

let Services_Json = ServicesJson.services[0]

const shortenText = (text) => {
    const words = text.split(' ');

    let shortened
    if (words.length > 40) {
        shortened = words.slice(0, 20).join(' ') + '...';
    }

    return shortened
};

export default function Services(props) {
 let sliceLen = props.displaySummaryFull ? Services_Json.length: 9

  return (
    <div className='container mb-5'>
        {
            props.displaySummaryFull ?
            <></>: 
            <div className='row text-center d-flex align-items-center justify-content-center mt-5'>
                <h1>Our Services</h1>
            </div>
        }
        
        
        <div className='row justify-content-between text-center'>
             {
                Services_Json.slice(0, sliceLen).map(data => 
                    {
                        return (<div className="card col-4" style={cardStyle}>
                                    {
                                        props.displaySummaryFull ? <></> : <>
                                            <img  src={data.img_src} className="card-img-top" alt="..." style={cardImageStyle}/>
                                        </>
                                    }
                                
                                
                                    {
                                        props.displaySummaryFull ? <>
                                            <a type="button" href={'/services?service='+data.linkName} className="learn-more-link btn btn-outline-dark" style={{marginTop: "auto"}}>{data.title}</a>
                                        </>: 
                                        <>
                                            <br></br>
                                            <div className="card-body d-flex flex-column">
                                                <h5 className="card-title">{data.title}</h5>
                                                <p className="card-text">{shortenText(data.text[0])}</p>
                                                <a type="button" href={'/services?service='+data.linkName} className="learn-more-link btn btn-outline-dark" style={{marginTop: "auto"}}>Learn More</a>
                                            </div>
                                        </>
                                    }
                                </div>)
                    })
            }
        </div>
    </div>
  )
}
