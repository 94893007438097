import React, { useState, useEffect} from 'react';
import treelogo from '../imgs/CypressAA/RGB Logo/CypressAA.png'
import './NavBar.css'

//import services json
import ServicesJson from '../db/services.json'

let Services_Json = ServicesJson.services[0]

function NavBar() {
    let [NavXButton, setNavXButton] = useState(false);
    let [isMobile, setIsMobile] = useState(false);
    let [currentPage, setCurrentPage] = useState('home');

    const handleResize = () => {
        if (window.innerWidth <= 990) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    // create an event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light navbar-height">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" onClick={() => setNavXButton(true)}>
                    <span className="navbar-toggler-icon" ></span>
                </button>

                <a className="navbar-brand" href="/">
                    <img  src={treelogo} alt="" width="25%" height="20%" className="d-inline-block align-text-top logo"/>
                    
                </a>
                <div className="offcanvas offcanvas-start poppins_reg"  id="offcanvasExample" data-bs-keyboard="false" data-bs-backdrop="false" aria-labelledby="offcanvasExampleLabel"> 
                    {
                        (isMobile)? <div className="offcanvas-header d-flex flex-row-reverse ">
                            <button type="button" className="btn-close text-reset navbar-toggler" data-bs-dismiss="offcanvas" aria-label="Close" onClick={()=> setNavXButton(false)}></button>
                            </div>:<div></div>
                    }
                    
                    <div className="offcanvas-body">
                        <ul className="navbar-nav ms-auto ">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/about">Meet Dr. Nguyen</a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/services" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {
                                        Services_Json.map(data => {
                                            return(<li><a className="dropdown-item" href={'/services?service='+data.linkName}>{data.title}</a></li>)
                                        })
                                    }
                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Patient Resources
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li><a className="dropdown-item" href="/">Pay My Bill</a></li>
                                    <li><a className="dropdown-item" href="/">Patient Forms</a></li>
                                </ul>
                            </li>
                            
                            <li className="nav-item">
                                <a className="nav-link" href="/contact">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
      </nav>

    );
}


export default NavBar;