import React from 'react'
import './Footer.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import treelogo from '../imgs/CypressAA/RGB Logo/CypressAA.png'

function Footer(props) {
  return (
    <footer className="text-center text-lg-start bg-white text-muted mt-5">
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
            
        </section>
        
        <section className="">
            <div className="container text-center text-md-start mt-5">
            
            <div className="row mt-3">
                
                <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                
                <h6 className="text-uppercase fw-bold mb-4">
                <img  src={treelogo} alt="" width="87%" height="25%" />
                </h6>
                <p>
                    Established in 2023, Cypress Allergy & Asthma 
                    is a boutique physician office specializing in the treatment of
                    Allergy and Asthma serving patients here in the Houston/Cypress area.
                </p>
                </div>
                

                
                <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                    Site Map
                </h6>
                <p>
                    <a href="/about" className="text-reset">About</a>
                </p>
                <p>
                    <a href="/Providers" className="text-reset">Providers</a>
                </p>
                <p>
                    <a href="/Contact" className="text-reset">Contact Us</a>
                </p>
                <p>
                    <a href="/Services" className="text-reset">Services</a>
                </p>
                </div>
                

                
                <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                    Patient Resources
                </h6>
                <p>
                    <a href="#!" className="text-reset">Pay My Bill</a>
                </p>
                <p>
                    <a href="#!" className="text-reset">Patient Forms</a>
                </p>
                <p>
                    <a href="#!" className="text-reset">Blog</a>
                </p>
                </div>
                

                
                <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                    <p>
                        9717 Jones Rd Suite 200
                    </p>
                    <p>
                        Houston, TX 77065
                    </p>
                    <p>
                        contact@cypressallergy.com
                    </p>
                    <p>
                        + 01 (832) 688-9463
                    </p>
                </div>
                
            </div>
            
            </div>
        </section>
        <div className="text-center p-5 bottomFooter">
            <p className="text-reset ">© 2023 Copyright |  Cypress Allergy & Asthma | All Rights Reserved | 
                <a className="text-reset" href="https://google.com"> Sitemap </a> | 
                <a className="text-reset" href="https://google.com"> Terms Of Use </a> |
                <a className="text-reset" href="https://google.com"> Accessibility Statement </a>
            </p>
        </div>
    </footer>
  )
}


export default Footer
