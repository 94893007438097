import React from 'react'
import { HashLink } from 'react-router-hash-link';

//Components
import Body from '../components/Body'
import Services from '../components/ServicesSummaryDisplay'
import About from '../components/About-Short'
import Contact from '../components/Contact'



function Home(props) {
  return (
    <>
      <Body></Body>
      <About></About>
      <hr></hr>
      <Services displaySummary={true} 
                displaySummaryFull={false}/>
      <hr></hr>

      <HashLink smooth to="#contact"/>
      <Contact></Contact>
    </>
  )
}

Home.propTypes = {}

export default Home
