import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

//Pages
import Home from './pages/Home';
import About from './pages/About';
import ErrorPage from './pages/Error';
import ServicesPage from './pages/Services';
import ContactPage from './pages/Contact';

//Components
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Map from './components/Map';

//Data
import ServicesJson from './db/services.json'

let Services_Json = ServicesJson.services[0]

let ServiceRoutes = () => {
  let routes = [
    {
      index: true,
      element: <ServicesPage 
                displaySummaryFull={true}
                service="allergies"/>
    }
  ]

  Services_Json.map(data => {
    routes.push({
      path: data.linkName,
      element: <ServicesPage  
                displaySummaryFull={true}
                displaySpecificService={true}
                service={data.title}/>
    })
  })


  return routes
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
    errorElement: <ErrorPage/>
  },
  {
    path: "/about",
    element: <About></About>,
  },
  {
    path: "/services",
    children: [
      {
        index: true,
        element: <ServicesPage 
                  displaySummaryFull={true}
                  service="allergies"/>
      },
      {
        path: "*",
        element: <ServicesPage  
                  displaySummaryFull={true}
                  displaySpecificService={true}/>
      }
    ]
  },
  {
    path: "/contact",
    element: <ContactPage></ContactPage>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NavBar></NavBar>
      <RouterProvider router={router} />
    <Map></Map>
    <Footer></Footer>
  </React.StrictMode>
);
