import React, { useState } from 'react'
import './Body.css'

import jumbotronImage from '../imgs/forest.jpg'


function Body() {
  return (
    <div className='bg-light p-5 rounded-lg jumbotron text-center d-flex align-items-center justify-content-center poppins_reg'>
      <div>
          <h1 className="display-4 fw-bold ">Cypress's Specialist In </h1>
          <h1 className="display-4 fw-bold ">Asthma, Allergy, & Immunology</h1> 
          <hr className=" breakline "/>
          <div > 
              <a className="btn btn-dark btn-lg learnMoreBtn" href="/services" role="button">Learn more</a>
              <a className="btn btn-primary btn-lg " href="/#contact" role="button">Contact Us</a>
          </div>
      </div> 
    </div>
  )
}

export default Body
