import React from 'react'

function Map(props) {
  return (
    <div>
          <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d363.5033661040482!2d-95.58549430582485!3d29.91481028190898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640d1013e8aedfb%3A0xdce15a4ee5ab2e6e!2sCypress%20Allergy%20%26%20Asthma!5e0!3m2!1sen!2sus!4v1692977207951!5m2!1sen!2sus" 
              width="100%" 
              height="500em" 
              style={{ border: "0" }}
              allowfullscreen="" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>
    </div>
  )
}

export default Map
