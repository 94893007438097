import React from 'react'
import About from '../components/About-Long'


function AboutPage() {
  return (
    <>
        <About></About>
    </>
  )
}

export default AboutPage
