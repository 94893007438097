import React, {useState, useEffect} from 'react'
import axios from 'axios';


function Contact() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
          const isScriptExist = document.getElementById(id);
       
          if (!isScriptExist) {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            script.id = id;
            document.body.appendChild(script);
          }
       
          if (isScriptExist && callback) callback();
        }
       
        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_reCAPTCHA_SITE_KEY}`);
      }, []);



    let handleOnClick = (e) => {
        
        e.preventDefault()
        setLoading(true)
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(process.env.REACT_APP_reCAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
                submitData(token);
            });
        });
        setLoading(false)
    }


    const submitData = token => {
        // call a backend API to verify reCAPTCHA response
        let body = {
            "from": email,
            "subject": `${name} needs help`,
            "body":`
                <h2>Email: ${email} </h2>
                <h5>Name: ${name} </h5>
                <h5>Phone Number: ${phone} </h5>
                <p>Comments/Concerns: ${comment}</p>`,
            "reCaptchaToken":token
        }

        const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
        const wrapper = document.createElement('div')

        //dev: https://localhost:7133/api/email
        axios.post('https://caabackend.azurewebsites.net/api/email', body, {
            headers: {
                'Access-Control-Allow-Origin' : '*'
            }
          })
             .then((response) => {

                wrapper.innerHTML = [
                    `<div class="alert alert-success alert-dismissible mt-3" role="alert">`,
                    `   <div>"Dr. Johnny and his team have been notified with your concerns and will attempt to 
                    get back to you immediately. 
                    Please call (832) 688-9463 if there is an immediate emergency."</div>`,
                    '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
                    '</div>'
                  ].join('')

                alertPlaceholder.append(wrapper)
             })
             .catch((error) => {

                wrapper.innerHTML = [
                    `<div class="alert alert-danger alert-dismissible mt-3" role="alert">`,
                    `   <div>"Your concerns failed to send. Please try again.
                    Please call (832) 688-9463 if there is an immediate emergency."</div>`,
                    '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
                    '</div>'
                  ].join('')

                alertPlaceholder.append(wrapper)
             })

    }

    return (
        <div className='container mb-5 align-items-center justify-content-center' id='contact'>
            <div className='text-center mb-3'>
                <h5>Contact Us</h5>
                <h2>Send A Message To Dr. Johnny Nguyen, D.O., FACAAI</h2>
                <h6>If you have any questions, concerns, or comments regarding Dr. Johnny Nguyen, please fill out the short contact form below.</h6>
            </div>
            <form>
            <fieldset >
                <div className="mb-3">
                    <label for="NameInput" className="form-label">Name</label>
                    <input type="text" 
                        id="NameInput" 
                        className="form-control" 
                        placeholder="Name" 
                        onChange={e=>setName(e.target.value)}
                        value={name}
                    />
                </div>
                <div className="mb-3">
                    <label for="EamilInput" className="form-label">Email</label>
                    <input type="text" 
                        id="EamilInput" 
                        className="form-control" 
                        placeholder="Email"
                        onChange={e=>setEmail(e.target.value)}
                        value={email}
                    />
                </div>
                <div className="mb-3">
                    <label for="PhoneNumberInput" className="form-label">Phone Number</label>
                    <input type="text" 
                        id="PhoneNumberInput" 
                        className="form-control" 
                        placeholder="Phone Number"
                        onChange={e=>setPhone(e.target.value)}
                        value={phone}
                    />
                </div>
                <div className="mb-3">
                    <label for="exampleFormControlTextarea1" className="form-label">Comment/Concerns</label>
                    <textarea 
                        className="form-control" 
                        id="exampleFormControlTextarea1" 
                        rows="4"
                        placeholder='Please Leave Your Comments and Concerns'
                        onChange={e=>setComment(e.target.value)}
                        value={comment}
                    />
                </div>

                <div className="d-grid gap-2">
                    <button type="submit" className="btn-lg btn-primary submitBtn" onClick={handleOnClick} disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </fieldset>

            <div id="liveAlertPlaceholder"/>
            </form>
        </div>
    )
}


export default Contact
